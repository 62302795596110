import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const rootTitle = 'GPU Audio'
const notFound = 'Page Not Found'

const PageTitles = new Map(Object.entries({
  '': 'Home',
  'products': 'Partner Products',
  jobs: 'Jobs',
  'user-profile': 'User Profile',
  'cookie-policy': 'Cookie Policy',
  'terms-of-use': 'Terms of Use',
  'privacy-policy': 'Privacy Policy',
  'request-sdk': 'Request SDK',
  'partners': 'Partners',
  'newsfeed': 'News & Events',
  'use-our-tech': 'Use Our Tech',
  support: 'Support',
  'sign-in': 'Sign in',
  'sign-up': 'Sign up',
  'recovery': 'Password Recovery',
  'resend-confirmation': 'Resend Confirmation email',
  'change-password': 'Change Password',
}))

export const setTitle = (prefix?: string): void => {
  document.title = prefix ? `${prefix} | ${rootTitle}` : rootTitle
}

export const usePageTitles = (): void => {
  const location = useLocation()

  useEffect(
    () => {
      const [, rootPath, uriAlias] = location.pathname.split('/')

      if (['newsfeed', 'jobs'].includes(rootPath) && uriAlias) {
        return
      }

      if (PageTitles.has(rootPath)) {
        setTitle(PageTitles.get(rootPath))
      } else {
        setTitle(notFound)
      }
    },
    [location.pathname],
  )
}
