import * as React from 'react'
import classNames from 'classnames'

import exitCross from './assets/exit-cross.svg'

import classes from './style.module.scss'

interface ComponentProps {
  onClick: () => void
  className?: string
}

export const CloseButton: React.FC<ComponentProps> = ({ onClick, className }) => (
  <button
    className={classNames(classes.button, className)}
    onClick={onClick}
  >
    <img
      src={exitCross}
      width={30}
      height={30}
      alt="exit cross"
    />
  </button>
)

CloseButton.defaultProps = {
  className: undefined,
}
