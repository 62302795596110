import * as React from 'react'

export const useKeyboardAction = (callback: () => void, code = 'Escape'): void => {
  const closePopupOnButton = React.useCallback((event: KeyboardEvent) => {
    if (event.code === code) {
      callback()
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('keydown', closePopupOnButton)

    return () => {
      document.removeEventListener('keydown', closePopupOnButton)
    }
  }, [closePopupOnButton])
}
