import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/register-hovered.svg'

interface ComponentProps {
  link: string
}

export const ActionButtonRegister: React.FC<ComponentProps> = ({ link }) => {
  return (
    <Button
      as="link"
      href={link}
      target="_blank"
      iconHovered={icon}
    >
      Register now
    </Button>
  )
}
