import React, { FC } from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'

import { NewsfeedRecordType } from 'core/interfaces'

import classes from './style.module.scss'

interface ComponentProps {
  type: NewsfeedRecordType
  date?: string
  className?: string
}

export const NewsfeedTag: FC<ComponentProps> = (props) => {
  const {
    type,
    date,
    className,
  } = props

  const isEvent = type === NewsfeedRecordType.Event

  return (
    <div className={classNames(classes.container, className)}>
      <div
        className={classNames(
          classes.tag,
          isEvent ? classes.events : classes.news,
        )}
      >
        <Text type={TextName.Subtext}>
          { isEvent ? 'Events' : 'News' }
        </Text>
      </div>

      { (date && isEvent) && (
        <div className={classNames(classes.tag, classes.dateTag)}>
          <Text type={TextName.Subtext}>
            { date }
          </Text>
        </div>
      ) }
    </div>
  )
}
