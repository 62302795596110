import * as React from 'react'
import { MouseParallaxContainer, MouseParallaxChild } from 'react-parallax-mouse'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { ActionButtonDiscover } from 'sdk/action-buttons/ActionButtonDiscover'
import { useAppSelector } from 'redux/store'
import underglow from 'shared/assets/underglow.png'

import { SectionId } from 'core/interfaces'

import trombone from './assets/trombone.png'
import violin from './assets/violin.png'
import clarinet from './assets/clarinet.png'
import laptop from './assets/laptop.png'

import classes from './style.module.scss'

export const FlagshipProduct: React.FC = () => {
  const vslLink = useAppSelector((state) => state.settings.links?.mir_pro_3d)

  return (
    <Content
      id={SectionId.FlagshipProduct}
      className={classes.content}
    >
      <MouseParallaxContainer
        globalFactorX={0.015}
        globalFactorY={0.015}
        resetOnLeave
        useWindowMouseEvents
        containerStyle={{ overflow: 'visible' }}
        className={classes.productContainer}
      >
        <img
          className={classes.glow}
          src={underglow}
          alt="underglow"
        />

        <Text
          className={classes.title}
          type={TextName.SuperBigText}
        >
          VIENNA POWER&nbsp;HOUSE
        </Text>

        <img
          className={classes.laptop}
          src={laptop}
          width={1036}
          height={694}
          alt="mac"
        />

        <MouseParallaxChild
          factorX={1.5}
          factorY={1.5}
          className={classes.trombone}
        >
          <img
            src={trombone}
            width={866}
            height={358}
            alt="violin"
          />
        </MouseParallaxChild>

        <MouseParallaxChild
          className={classes.violin}
        >
          <img
            src={violin}
            width={450}
            height={292}
            alt="violin"
          />
        </MouseParallaxChild>

        <MouseParallaxChild
          factorX={2.4}
          factorY={2.4}
          className={classes.clarinet}
        >
          <img
            src={clarinet}
            width={680}
            height={314}
            alt="clarinet"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>

      <Text
        type={TextName.Text}
        className={classes.text}
      >
        Vienna Power House is a groundbreaking add-on for Vienna MIR Pro 3D
        that offloads convolution processes to the graphics card in order
        to reduce the load on your CPU
      </Text>

      <ActionButtonDiscover
        href={vslLink!}
      />
    </Content>
  )
}
