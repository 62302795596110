import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, NewsfeedTag } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { ActionButtonLearnMore } from 'sdk/action-buttons/ActionButtonLearnMore'
import { ActionButtonRegister } from 'sdk/action-buttons/ActionButtonRegister'

import { newsfeedLink } from 'core/utils'
import { NewsfeedEntry, NewsfeedRecordType } from 'core/interfaces'

import classes from './style.module.scss'

interface ComponentProps {
  entry: NewsfeedEntry
  showLearnMore: boolean
}

export const NewsfeedBigCard: FC<ComponentProps> = (props) => {
  const { entry } = props
  const navigate = useNavigate()

  return (
    <Content
      first
      className={classes.container}
      style={{ backgroundImage: `url(${entry?.bg_image.url})` }}
    >
      <div className={classes.infoBlock}>
        <div className={classes.titleBlock}>
          <NewsfeedTag
            type={entry.type}
            date={entry.human_date}
          />

          <Text type={TextName.H1}>
            { entry.title }
          </Text>
        </div>

        <Text type={TextName.Text}>
          { entry.preview }
        </Text>

        <div className={classes.buttonsBlock}>
          {
            props.showLearnMore ? <ActionButtonLearnMore onClick={() => navigate(newsfeedLink(entry))} /> : undefined
          }
          {
            entry.type === NewsfeedRecordType.Event && entry.registration_link ? (
              <ActionButtonRegister link={entry.registration_link} />
            ) : undefined
          }
        </div>
      </div>
    </Content>
  )
}
