import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'

export enum PopupId {
  SignOut,
  Download,
  ApplyToJob,
  NotificationOldUser,
  NotificationNewUser
}

export interface PopupState {
  activePopup: PopupId | null
}

const initialState: PopupState = {
  activePopup: null,
}

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showPopup: (state, action: PayloadAction<PopupId>) => {
      state.activePopup = action.payload
    },
    closePopup: (state) => {
      state.activePopup = null
    },
  },
})

export const { showPopup, closePopup } = popupSlice.actions
export default popupSlice.reducer
