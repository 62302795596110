import * as React from 'react'

import { Content, ImageTextBlock } from 'sdk/components'
import { Text, TextName } from 'sdk'

import { data } from './data'

import classes from './style.module.scss'

export const NewPossibilities: React.FC = () => {
  return (
    <Content
      className={classes.content}
    >
      <Text type={TextName.H2} className={classes.title}>
        Unlocking New Possibilities in Audio
      </Text>

      <Text type={TextName.Subtext} className={classes.subtitle}>
        Driving a next generation of audio workflows
      </Text>

      <div className={classes.possibilities}>
        { data.map((item) => (
          <ImageTextBlock
            key={item.title}
            title={item.title}
            description={item.description}
            image={item.image}
            textFirst={item.textFirst}
          />
        )) }
      </div>
    </Content>
  )
}
