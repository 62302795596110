import * as React from 'react'
import classNames from 'classnames'

import './style.scss'

interface ComponentProps {
  children: string
  className?: string
  qlEditor?: boolean
}

export const UnsafeHTML: React.FC<ComponentProps> = (props) => {
  const { className, children, qlEditor } = props

  return (
    <div
      dangerouslySetInnerHTML={{ __html: children }}
      className={classNames({ 'ql-editor': qlEditor }, className)}
    />
  )
}

UnsafeHTML.defaultProps = {
  className: undefined,
  qlEditor: true,
}
