import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/learn-hovered.svg'

interface ComponentProps {
  className?: string
  onClick?: () => void
  href?: string
}

export const ActionButtonLearnMore: React.FC<ComponentProps> = ({ onClick, href, className }) => {
  return (
    <Button
      as={href ? 'link' : 'button'}
      target={href ? '_blank' : undefined}
      onClick={onClick}
      href={href}
      iconHovered={icon}
      className={className}
    >
      Learn more
    </Button>
  )
}
