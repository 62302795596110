import * as React from 'react'
import classNames from 'classnames'

import { useAppSelector } from 'redux/store'

import { clickNames } from 'core/utils'

import {
  discord,
  facebook,
  instagram,
  linkedIn,
  twitter,
  youtube,
} from '../svg/icons'
import { TrackALink } from '../track-link'

import classes from './style.module.scss'

interface ComponentProps {
  className?: string
  linkClassName?: string
  downloadOverlay?: boolean
}

interface SocialNetworkProps {
  icon: React.ReactNode
  href: string
  trackName: clickNames
}

type socialMedia = |
  'discord' |
  'twitter' |
  'youtube' |
  'facebook' |
  'instagram' |
  'linkedin'

export const SocialNetworks: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { className, downloadOverlay } = props
  const { links } = useAppSelector((state) => state.settings)

  const sn: {
    [name in socialMedia]: SocialNetworkProps
  } = {
    discord: {
      trackName: 'Discord',
      icon: discord,
      href: links!.discord,
    },
    twitter: {
      trackName: 'Twitter',
      icon: twitter,
      href: 'https://twitter.com/gpuaudio',
    },
    youtube: {
      trackName: 'Youtube',
      icon: youtube,
      href: 'https://www.youtube.com/gpuaudio',
    },
    facebook: {
      trackName: 'Facebook',
      icon: facebook,
      href: 'https://www.facebook.com/GpuAudio/',
    },
    instagram: {
      trackName: 'Instagram',
      icon: instagram,
      href: 'https://www.instagram.com/gpuaudio/',
    },
    linkedin: {
      trackName: 'Linked_in',
      icon: linkedIn,
      href: 'https://www.linkedin.com/company/gpuaudio',
    },
  }

  let networks: SocialNetworkProps[]

  if (downloadOverlay) {
    networks = [sn.twitter, sn.youtube, sn.facebook, sn.instagram, sn.linkedin]
  } else {
    networks = [sn.discord, sn.twitter, sn.youtube, sn.facebook, sn.instagram, sn.linkedin]
  }

  return (
    <div className={classNames(classes.container, className)}>
      { networks.map((item) =>
        <TrackALink
          key={item.trackName}
          className={classes.iconLink}
          href={item.href}
          target="_blank"
          rel="noreferrer"
          trackName={item.trackName}
        >
          { item.icon }
        </TrackALink>,
      ) }
    </div>
  )
}

SocialNetworks.defaultProps = {
  className: undefined,
  downloadOverlay: false,
}
