import * as React from 'react'

import { Content } from 'sdk/components'
import { Text, TextName } from 'sdk'
import { Button } from 'sdk/button/Button'
import { useAppSelector } from 'redux/store'

import supportBg from './assets/support-bg.jpeg'

import classes from './style.module.scss'

export const Support: React.FC = () => {
  const contactEmail = useAppSelector((state) => state.settings.links?.contact_email)

  return (
    <Content
      mainWrapperClassName={classes.mainWrapper}
      className={classes.content}
      style={{ backgroundImage: `url(${supportBg})` }}
    >
      <div className={classes.block}>
        <Text
          type={TextName.H1}
          className={classes.title}
        >
          Get in Touch
        </Text>

        <Text
          type={TextName.Text}
          className={classes.subtitle}
        >
          Contact us about Partnerships,
          <br/>
          Product Support, and Feedback here
        </Text>

        <Button
          className={classes.button}
          as="link"
          target="_blank"
          href={`mailto:${contactEmail!}`}
        >
          Submit
        </Button>
      </div>
    </Content>
  )
}
