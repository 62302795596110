import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'

import { useAppDispatch } from 'redux/store'
import { closePopup } from 'redux/popupReducer'

import { disableScroll } from 'core/utils'
import { useKeyboardAction } from 'core/hooks'

import classes from './style.module.scss'

interface ComponentProps {
  children: React.ReactNode
  inRouter: boolean
}

export const PopupContainer: React.FC<ComponentProps> = ({ children, inRouter }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onClose = React.useCallback((): void => {
    if (inRouter) {
      navigate(-1)
    } else {
      dispatch(closePopup())
    }
  }, [inRouter])

  useKeyboardAction(onClose)

  React.useEffect(() => {
    disableScroll(true)

    return () => {
      disableScroll(false)
    }
  }, [])

  return (
    <div
      className={classnames(classes.container)}
    >
      <div
        className={classes.clickableBlock}
        onClick={onClose}
      />

      { children }
    </div>
  )
}
