import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { scrollToId } from 'core/utils/scrollToId'
import { SectionId } from 'core/interfaces'

export const useScrollToHash = (): void => {
  const location = useLocation()

  const hash = location.hash.slice(1) as SectionId

  useEffect(() => {
    location.hash !== '' ? scrollToId(hash, 1.01) : null
  }, [location.key])
}
