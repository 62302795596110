import * as React from 'react'
import { Link } from 'react-router-dom'

import classes from './style.module.scss'

interface ComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  link: string
  external: boolean
}

export const MenuItemFooter: React.FC<ComponentProps> = ({
  children,
  link,
  external,
  ...rest
}) => {
  return (
    <div
      className={classes.footerContainer}
      {...rest}
    >
      {
        external ? (
          <a
            className={classes.footerText}
            href={link}
            target="_blank"
            rel="noreferrer"
          >{ children }</a>
        ) : (
          <Link
            to={link}
            className={classes.footerText}
          >
            { children }
          </Link>
        )
      }
    </div>
  )
}
