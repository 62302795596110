import React, { FC } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/store'
import { closeAlert } from 'redux/alertReducer'

import { Alert } from '../alert/Alert'

import classes from './style.module.scss'

export const FlashModal: FC = () => {
  const notice = useAppSelector((state) => state.alert.notice)
  const dispatch = useAppDispatch()

  return (
    <Alert
      className={classes.flashModal}
      variant="warning"
      onClose={() => dispatch(closeAlert())}
      show={!!notice}
    >
      { notice }
    </Alert>
  )
}
