import * as React from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import { UnsafeHTML } from 'components'
import { PopupId, showPopup } from 'redux/popupReducer'
import { useAppDispatch } from 'redux/store'
import { Content } from 'sdk/components'

import { JobFullRecord } from 'core/interfaces'
import { setTitle, trackClick } from 'core/utils'

import classes from '../style.module.scss'
import { introText } from '../data'

export const JobItemPage: React.FC = () => {
  const job = useLoaderData() as JobFullRecord
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!job?.id) {
      navigate('/not-found')
      return
    }
    setTitle(job.title)
  }, [])

  if (!job?.id) {
    return null
  }

  return (
    <Content
      first
      className={classes.container}
    >
      <h2 className={classes.heading}>
        { job.title }
      </h2>

      <div className={classes.intro}>
        { introText.map((paragraph, key) => (
          <p key={key}>
            { paragraph }
          </p>
        )) }
      </div>

      <UnsafeHTML
        className={classes.description}
      >
        { job.content }
      </UnsafeHTML>

      <div className={classes.actions}>
        <button
          className={classes.button}
          onClick={() => {
            trackClick('Apply_to_job')
            dispatch(showPopup(PopupId.ApplyToJob))
          }}
          type="button"
        >
          Apply For Job
        </button>

        <Link className={classes.goBack} to="/jobs">See all jobs</Link>
      </div>
    </Content>
  )
}
