import { configureStore } from '@reduxjs/toolkit'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector } from 'react-redux'

import { EntryPointProps } from 'core/interfaces'

import userReducer, { loginUser } from './userReducer'
import popupReducer from './popupReducer'
import alertReducer from './alertReducer'
import settingsReducer, { initApp } from './settingsReducer'

import type { TypedUseSelectorHook } from 'react-redux'

const store = configureStore({
  reducer: {
    user: userReducer,
    popup: popupReducer,
    alert: alertReducer,
    settings: settingsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const initializeStore = (
  initialStore: typeof store,
  { user, links, mobile, debug, has_jobs, flash }: EntryPointProps,
): void =>  {
  initialStore.dispatch(initApp({ links, mobile, debug, hasJobs: has_jobs, flash }))
  initialStore.dispatch(loginUser(user))
}

export default store
