import * as React from 'react'
import classNames from 'classnames'

import { Button } from 'sdk/button/Button'

import icon from '../assets/logout-hovered.svg'

import classes from './style.module.scss'

interface ComponentProps {
  onClick: () => void
  className?: string
}

export const ActionButtonLogOut: React.FC<ComponentProps>  = ({ onClick, className }) => {
  return (
    <Button
      onClick={onClick}
      className={classNames(classes.button, className)}
      iconHovered={icon}
    >
      Log out
    </Button>
  )
}
