import { createSlice } from '@reduxjs/toolkit'

import { UserRecord } from 'core/interfaces'

import type { PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  loggedIn: boolean
  user: UserRecord | undefined
}

const initialState: UserState = {
  loggedIn: false,
  user: undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<UserRecord>) => {
      state.user = action.payload
      state.loggedIn = !!action.payload
    },
    logoutUser: (state) => {
      state.user = undefined
      state.loggedIn = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  loginUser,
  logoutUser,
} = userSlice.actions

export default userSlice.reducer
