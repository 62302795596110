const getCsrfMetaElement = (): HTMLMetaElement => {
  return document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
}

export const getCsrfToken = (): string => {
  return getCsrfMetaElement().content
}

export const setCsrfToken = (token: string | null): void => {
  if (!token) {
    return
  }

  getCsrfMetaElement().setAttribute('content', token)
}

const buildHeaders = (method: HttpMethod, contentType: ContentType): HeadersInit => {
  const headers = method === HttpMethod.Get ? {} : {
    'X-CSRF-Token': getCsrfToken(),
  }

  if (contentType !== ContentType.JSON) {
    return headers as HeadersInit
  }

  return {
    ...headers,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  } as HeadersInit
}

const serializeBody = (contentType: ContentType, data: Record<string, unknown> | FormData): BodyInit => {
  switch (contentType) {
    case ContentType.JSON:
      return JSON.stringify(data)
    case ContentType.FormData:
      return data as FormData
  }
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export enum ContentType {
  JSON,
  FormData,
}

export const apiRequest = <T>(
  url: string,
  body: Record<string, unknown> | FormData,
  method = HttpMethod.Post,
  contentType = ContentType.JSON,
): Promise<T> => fetch(
    url,
    {
      method,
      headers: buildHeaders(method, contentType),
      body: serializeBody(contentType, body),
      credentials: 'same-origin',
    },
  ).then((response) => {
    if (method !== HttpMethod.Get) {
      const token = response.headers.get('X-CSRF-Token')
      setCsrfToken(token)
    }

    return response
  }).then(async (response) => {
    const responseJson = await response.json()

    if (response.ok) {
      return responseJson
    } else {
      throw responseJson
    }
  })
