import * as React from 'react'

import { Content, YoutubeEmbed } from 'sdk/components'

import classes from './style.module.scss'

export const FlagshipReview: React.FC = () => {
  return (
    <Content
      className={classes.content}
    >
      <YoutubeEmbed
        embedId="-JmM70RkRLY"
        className={classes.video}
      />
    </Content>
  )
}
