import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import { Alert } from 'sdk/components/alert/Alert'

import classes from './style.module.scss'

interface ComponentProps {
  onSubmit: () => void
  className?: string
  baseError: string
}

export const PopupForm: FC<PropsWithChildren<ComponentProps>> = ({
  children,
  className,
  onSubmit,
  baseError,
}) => (
  <form
    onSubmit={onSubmit}
    className={classNames(classes.form, className)}
  >
    <Alert
      variant="error"
      show={baseError !== ''}
    >
      { baseError }
    </Alert>

    { children }
  </form>
)
