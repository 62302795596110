import * as React from 'react'

import { clickNames, trackClick } from 'core/utils'

interface TrackLinkProps {
  href: string
  trackName: clickNames
  children: React.ReactNode
  className?: string
  target?: '_blank'
  rel?: 'noreferrer'
  onClick?: () => void
}

export const TrackALink: React.FC<TrackLinkProps> = ({
  href,
  className,
  trackName,
  children,
  target,
  rel,
  onClick,
}) => (
  <a
    href={href}
    className={className}
    onClick={() => {
      trackClick(trackName)

      if (onClick) {
        onClick()
      }
    }}
    target={target}
    rel={rel}
  >
    { children }
  </a>
)

TrackALink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
}
