import * as React from 'react'
import { CSSProperties } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

export const enum TextName {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  Text = 'text',
  TextBold = 'textBold',
  Subtext = 'subtext',
  SuperBigText = 'superBigText'
}

interface ComponentProps {
  type: TextName
  style?: CSSProperties
  className?: string
}

export const Text: React.FC<React.PropsWithChildren<ComponentProps>> = (props) => {
  const {
    type,
    children,
    style,
    className,
  } = props

  let elementTag: string
  switch (type) {
    case TextName.H1:
      elementTag = 'h1'
      break
    case TextName.H2:
      elementTag = 'h2'
      break
    case TextName.H3:
      elementTag = 'h3'
      break
    case TextName.Text:
      elementTag = 'p'
      break
    case TextName.TextBold:
      elementTag = 'p'
      break
    case TextName.Subtext:
      elementTag = 'span'
      break
    case TextName.SuperBigText:
      elementTag = 'strong'
      break
  }

  return React.createElement(
    elementTag,
    {
      className: classNames(
        classes.textElement,
        classes[type],
        className,
      ),
      style,
    },
    children,
  )
}
