import React, { CSSProperties, FC, ReactNode } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps {
  children: ReactNode
  mainWrapperStyle?: CSSProperties
  style?: CSSProperties
  wrapperStyle?: CSSProperties
  first?: boolean
  className?: string
  mainWrapperClassName?: string
  wrapperClassName?: string
  element?: 'section' | 'footer' | 'header' | 'div'
  image?: string
  imageClassName?: string
  id?: string
  videoLink?: string
}

export const Content: FC<ComponentProps> = (props) => {
  const {
    children,
    style,
    wrapperStyle,
    first,
    className,
    mainWrapperStyle,
    mainWrapperClassName,
    wrapperClassName,
    element,
    image,
    imageClassName,
    id,
    videoLink,
  } = props

  const Element = element as keyof JSX.IntrinsicElements

  return (
    <Element
      className={classNames(classes.mainWrapper, { [classes.first]: first }, mainWrapperClassName)}
      style={mainWrapperStyle}
      id={id}
    >
      { image && (
        <img
          src={image}
          alt="background image"
          className={classNames(classes.image, imageClassName)}
        />
      ) }

      { videoLink && (
        <video
          loop
          autoPlay
          muted
          playsInline
          className={classNames(classes.image, imageClassName)}
        >
          <source src={videoLink} type="video/webm" />
        </video>
      ) }

      <div
        className={classNames(classes.wrapper, wrapperClassName)}
        style={wrapperStyle}
      >
        <div
          className={classNames(classes.inner, className)}
          style={style}
        >
          { children }
        </div>
      </div>
    </Element>
  )
}

Content.defaultProps = {
  element: 'section',
  mainWrapperClassName: undefined,
  wrapperClassName: undefined,
  className: undefined,
  wrapperStyle: undefined,
  mainWrapperStyle: undefined,
  style: undefined,
  image: undefined,
  imageClassName: undefined,
  first: false,
  videoLink: undefined,
}
