import plugin from './assets/plugin.jpeg'
import instrument from './assets/instrument.jpeg'
import audio from './assets/audio.jpeg'

export const data = [
  {
    title: 'New generation of plugins',
    description: 'GPU Audio technology will enable plugins ' +
      'to go deeper and further than ever before. The unparalleled ' +
      'processing power offered by Graphics Cards eclipse what can ' +
      'be done with conventional CPU processing, putting a new world ' +
      'of possibilities in the hands of the planet\'s leading developers',
    image: plugin,
    textFirst: false,
  },
  {
    title: 'New generation of instruments',
    description: 'GPU Audio technology will define the next generation ' +
      'of music and audio instruments. Offering parallel processing directly ' +
      'from your Graphics Card, we\'re putting an untapped world of power ' +
      'into the plugins and devices of the future. Get ready for ultra-low ' +
      'latency response times with complex processing offloaded to your ' +
      'GPU for the first time',
    image: instrument,
    textFirst: true,
  },
  {
    title: 'New generation of audio production workflows',
    description: 'GPU Audio\'s patented technology delivers unmatched ' +
      'processing power to developers, producers, and artists the world ' +
      'over. Our solution enables supercharged audio direct from your GPU, ' +
      'meaning we\'ll be powering a new era of live A.I. processing, machine ' +
      'learning, and embedded hardware.',
    image: audio,
    textFirst: false,
  },
]
