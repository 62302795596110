import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { NewsfeedTag } from 'sdk/components'
import { Text, TextName } from 'sdk'

import { newsfeedLink } from 'core/utils'
import { NewsfeedEntry } from 'core/interfaces'

import classes from './style.module.scss'

interface ComponentProps {
  entry: NewsfeedEntry
}

export const NewsfeedCard: FC<ComponentProps> = (props) => {
  const { entry } = props

  return (
    <Link to={newsfeedLink(entry)} className={classes.container}>
      <div className={classes.imageBlock}>
        <img
          src={entry.bg_image.url}
          alt={entry.title}
        />
      </div>

      <div className={classes.infoBlock}>
        <NewsfeedTag
          type={entry.type}
          date={entry.human_date}
        />
        <Text type={TextName.H3}>
          { entry.title }
        </Text>

        <Text type={TextName.Subtext}>
          { entry.preview }
        </Text>
      </div>
    </Link>
  )
}
