import * as React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

import { clickNames, trackClick } from 'core/utils'

interface TrackLinkProps {
  to: string
  trackName: clickNames
  children: React.ReactNode
  className?: string
  activeClassName?: string
  onClick?: () => void
  end?: boolean
}

export const TrackLink: React.FC<TrackLinkProps> = ({
  to,
  className,
  activeClassName,
  trackName,
  children,
  onClick,
  end,
}) => (
  <NavLink
    to={to}
    className={({ isActive }) => classnames(className, isActive && activeClassName)}
    end={end}
    onClick={() => {
      trackClick(trackName)

      if (onClick) {
        onClick()
      }
    }}
  >
    { children }
  </NavLink>
)

TrackLink.defaultProps = {
  className: undefined,
  activeClassName: undefined,
  onClick: undefined,
  end: false,
}
