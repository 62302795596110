import * as React from 'react'
import { Link } from 'react-router-dom'

import classes from './style.module.scss'

interface ComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  onClick?: () => void
  link?: string
}

export const MenuItemHeader: React.FC<ComponentProps> = ({
  children,
  link,
  onClick,
  ...rest
}) => {
  return (
    <div
      className={classes.headerContainer}
      {...rest}
    >
      <Link
        to={link || '/'}
        className={classes.headerText}
        onClick={onClick}
      >
        { children }
      </Link>
    </div>
  )
}
