import React, { FC, PropsWithChildren } from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/enter-hovered.svg'

interface ComponentProps {
  onClick?: () => void
  disabled?: boolean
}

export const ActionButtonEnter: FC<PropsWithChildren<ComponentProps>> = (props) => {
  const { onClick, children, disabled } = props

  return (
    <Button
      resizable
      onClick={onClick}
      iconHovered={icon}
      disabled={disabled}
    >
      { children }
    </Button>
  )
}
