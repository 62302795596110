import { PreloadedData, PreloadedDataRequestId } from 'core/interfaces'

export const fetchPreloadedDataById = <T>(
  preloadedDataRequestId: PreloadedDataRequestId,
): T | undefined => {
  const preloadedData = fetchPreloadedData()

  if (
    !preloadedData ||
    preloadedData.type !== preloadedDataRequestId.type ||
    preloadedData.record !== preloadedDataRequestId.record
  ) {
    return
  }

  return preloadedData.data as T
}

export const savePreloadedData = (preloadedData: PreloadedData | null): void => {
  window.__GPUA_PDATA = preloadedData
}

export const fetchPreloadedData = (): PreloadedData | null => {
  const data = window.__GPUA_PDATA

  if (data !== null) {
    savePreloadedData(null)
  }

  return data
}
