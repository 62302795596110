import * as React from 'react'
import { useLoaderData } from 'react-router-dom'

import { Heading } from 'components'
import { Content } from 'sdk/components'

import { JobRecord } from 'core/interfaces'

import { JobItem } from './job-item/JobItem'

import classes from '../style.module.scss'
import { introText } from '../data'

export const JobsPage: React.FC = () => {
  const jobs = useLoaderData() as JobRecord[]

  return (
    <Content
      first
      className={classes.container}
    >
      <Heading
        element="h1"
        className={classes.heading}
        hr
      >
        Jobs
      </Heading>

      <div className={classes.intro}>
        { introText.map((paragraph, key) => (
          <p key={key}>
            { paragraph }
          </p>
        )) }
      </div>

      <br />

      { jobs.map((job) => (
        <JobItem key={job.id} job={job} />
      )) }
    </Content>
  )
}
