import * as React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { FormField, FormFieldSize, PopupForm } from 'sdk/components'
import { ActionButtonEnter } from 'sdk/action-buttons/ActionButtonEnter'

import { apiRequest, trackClick } from 'core/utils'

import tick from './images/tick.svg'

import classes from './style.module.scss'

interface Inputs {
  email: string
}

interface ComponentProps {
  apiUrl: string
}

export const SubmitEmailForm: React.FC<ComponentProps> = ({ apiUrl }) => {
  const { register, handleSubmit, reset, setError, formState: { errors, isSubmitting } } = useForm<Inputs>()

  const [linkSent, setLinkSent] = React.useState<boolean>(false)
  const onSubmit: SubmitHandler<Inputs> = (user) => apiRequest<void>(apiUrl, { user })
    .then(() => {
      setLinkSent(true)
      reset()
    })
    .catch((errors) => {
      errors.errors?.email && setError('email', { message: errors.errors.email })
    })

  return (
    <PopupForm
      baseError=""
      onSubmit={handleSubmit(onSubmit)}
    >
      { linkSent ? (
        <div className={classes.onSuccess}>
          <img
            src={tick}
            alt="tick"
          />
          Link sent, check your email
        </div>
      ) : (
        <FormField
          {...register('email')}
          fieldSize={FormFieldSize.small}
          label="E-mail: *"
          placeholder="Fill in your e-mail"
          type="email"
          error={errors.email?.message}
        />
      ) }

      <ActionButtonEnter
        onClick={() => trackClick('Submit_password_recovery')}
        disabled={isSubmitting || linkSent}
      >
        Send a recovery link
      </ActionButtonEnter>
    </PopupForm>
  )
}
