import React from 'react'
import classnames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps {
  className: string
  visible: boolean
}

export const LinearProgress: React.FC<ComponentProps> = ({ className, visible }) => {
  if (!visible) {
    return null
  }

  return (
    <progress className={classnames(classes.progress, className)} />
  )
}
