import { UserRecord } from 'core/interfaces'

/**
 * Important: click names shouldn't contain whitespaces. Use underscores instead.
 * Wrong: 'Sign in'
 * Right: 'Sign_in'
 */
export type clickNames = |
  'Cookie_policy' |
  'Terms_of_use' |
  'Free_beta_registration' |
  'Privacy_policy' |
  'Gpu_audio_logo' |
  'Early_access' |
  'Beta_suite' |
  'About' |
  'Product' |
  'Events' |
  'Blog' |
  'User_profile' |
  'Sign_in' |
  'Sign_out' |
  'Mobile_menu' |
  'Discord' |
  'Twitter' |
  'Linked_in' |
  'Youtube' |
  'Facebook' |
  'Instagram' |
  'Accept_cookies' |
  'Cancel_log_out' |
  'Submit_log_out' |
  'Submit_log_in' |
  'Password_recovery' |
  'Resend_confirmation' |
  'Submit_registration' |
  'Submit_password_recovery' |
  'Submit_change_password' |
  'Submit_account_details' |
  'My_products' |
  'Account_details' |
  'Change_password' |
  'Newsfeed_subscribe' |
  'Try_it_now' |
  'Submit_new_password' |
  'Download_product' |
  'Join_GPU_Audio_Community' |
  'Nvidia_logo' |
  'Amd_logo' |
  'Get_the_beta_suite' |
  'Contact_us' |
  'Sign_up_for_SDK' |
  'Facebook_oauth' |
  'Google_oauth' |
  'Notify_about_bundle' |
  'Notify_about_partner_plugins' |
  'Notify_about_partner_vsl' |
  'Notify_about_partner_mntra' |
  'Notify_about_partner_mach1' |
  'Products' |
  'Jobs' |
  'Apply_to_job' |
  'Product_readme' |
  'Get_free_plugins'

export const trackUid = (user: UserRecord): void => {
  if (!user) {
    return
  }

  window.dataLayer?.push({ UID: user.uid })
}

export const trackEvent = (
  category: 'Click' | 'Login' | 'Registration' | 'Session_Start',
  eventAction: string,
  extraProps: { [key: string]: string } = {},
): void => {
  window.dataLayer?.push({
    event: 'gpu-audio',
    EventCategory: category,
    EventAction: eventAction,
    EventLabel: Date.now(),
    ...extraProps,
  })
}

export const navigationEvent = (loggedIn: boolean): void => {
  window.dataLayer?.push({ userType: loggedIn ? 'logOn' : 'logOff' })
}

export const trackClick = (clickName: clickNames): void => {
  trackEvent('Click', clickName)
}
