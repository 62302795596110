import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/sdk-hovered.svg'

export const ActionButtonTryNow: React.FC = () => {
  return (
    <Button
      iconHovered={icon}
      as="router-link"
      to="/products"
      target="_blank"
    >
      Try Now
    </Button>
  )
}
