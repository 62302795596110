import * as React from 'react'

import { Content, ImageTextBlock } from 'sdk/components'

import { data } from './data'

import classes from './style.module.scss'

export const PartnerProducts: React.FC = () => {
  return (
    <Content
      className={classes.content}
    >
      { data.map((item) => (
        <ImageTextBlock
          key={item.title}
          title={item.title}
          description={item.description}
          image={item.image}
          textFirst={item.textFirst}
          buttonLink={item.link}
        />
      )) }
    </Content>
  )
}
