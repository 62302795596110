import livingSky from './assets/living-sky.jpeg'
import swam from './assets/swam.jpeg'

export const data = [
  {
    title: 'Spatial Reverb Plugin Set Redefining Three-Dimensional Spatial Audio Production',
    description: 'Living Sky is a limitless universe of living acoustic ' +
      'spaces that expands the capacity to effect and transform sound into ' +
      'the realms of your imagination. While some reverb plugins allow for ' +
      'simple motion within a space, Living Sky brings motion to the space ' +
      'itself – creating dynamic and expressive audio processing that expands ' +
      'and transforms organically.',
    image: livingSky,
    textFirst: false,
    link: 'https://www.mntra.io/announcing-living-sky',
  },
  {
    title: 'Audio Modeling’s Accelerated SWAM Product Line',
    description: 'Groundbreaking partnership that promises to redefine the ' +
      'possibilities of virtual instrument technology. This collaboration ' +
      'signifies a leap forward in the world of music production by integrating ' +
      'state-of-the-art GPU technology to amplify the capabilities of SWAM ' +
      'product line. The plan is to not only enhance existing products ' +
      'but also to breathe life into instruments that were once mere dreams',
    image: swam,
    textFirst: true,
    link: 'https://www.soundonsound.com/news/audio-modeling-partner-gpu-audio',
  },
]
