import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Header } from 'sdk/header/Header'
import { Footer } from 'sdk/footer/Footer'
import { useAppSelector } from 'redux/store'

import './style.scss'

interface ComponentProps {
  children: React.ReactNode
}

export const Layout: React.FC<ComponentProps> = ({ children }) => {
  const { pathname } = useLocation()
  const hash = location.hash.slice(1)
  const user = useAppSelector((state) => state.user.user)
  const hasJobs = useAppSelector((state) => state.settings.hasJobs)

  React.useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash])

  return (
    <>
      <Header user={user} />
      <main className="layout__main">
        { children }
      </main>

      <Footer showJobs={hasJobs!} />
    </>
  )
}
