import * as React from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps {
  children?: React.ReactNode
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
  svg?: React.ReactNode
  gradient?: boolean
  iconClassName?: string
  position?: 'center' | 'left' | 'right'
  hr?: boolean
}
export const Heading: React.FC<ComponentProps> = (props) => {
  const {
    children,
    element,
    className,
    svg,
    gradient,
    iconClassName,
    position,
    hr,
  } = props

  const Element = element as keyof JSX.IntrinsicElements

  let positionClass = ''

  if (position === 'center') {
    positionClass = classes.center
  } else if (position === 'right') {
    positionClass = classes.right
  } else if (position === 'left') {
    positionClass = classes.left
  }

  return (
    <div className={classNames(classes.container, positionClass, className)}>
      { svg && (
        <div className={classNames(classes.iconContainer, iconClassName)}>
          { svg }
        </div>
      ) }
      <Element
        className={classNames(classes.element, hr && classes.hr, gradient && classes.gradient )}
      >
        { children }
      </Element>
    </div>
  )
}

Heading.defaultProps = {
  element: 'h2',
  className: 'heading-title',
  iconClassName: undefined,
  svg: false,
  gradient: false,
  position: 'center',
}
