import * as React from 'react'

{ /* eslint-disable max-len */ }
export const discord =
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.4814 29.0097C24.4814 31.2183 22.8991 33.0154 20.9097 33.0154C18.9514 33.0154 17.3379 31.2183 17.3379 29.0097C17.3379 26.8021 18.9202 25.0039 20.9097 25.0039C22.9142 25.0039 24.5126 26.8183 24.4814 29.0097Z" fill="currentColor"/>
    <path d="M37.6865 29.0097C37.6865 31.2183 36.1203 33.0154 34.1147 33.0154C32.1564 33.0154 30.543 31.2183 30.543 29.0097C30.543 26.8021 32.1253 25.0039 34.1147 25.0039C36.1203 25.0039 37.7177 26.8183 37.6865 29.0097Z" fill="currentColor"/>
    <path d="M27.5 0C12.3127 0 0 12.3127 0 27.5C0 42.6873 12.3127 55 27.5 55C42.6873 55 55 42.6873 55 27.5C55 12.3127 42.6873 0 27.5 0ZM47.1485 37.6245C43.7497 40.121 40.4562 41.6367 37.2088 42.6411C37.1572 42.6572 37.1014 42.6379 37.0691 42.5938C36.3193 41.5497 35.6383 40.4486 35.041 39.2928C35.0066 39.2251 35.0378 39.1435 35.1087 39.1166C36.1904 38.7073 37.2195 38.2186 38.21 37.6406C38.2884 37.5944 38.2927 37.4827 38.2207 37.429C38.0112 37.2732 37.8028 37.1089 37.6041 36.9445C37.5665 36.9145 37.516 36.908 37.4741 36.9284C31.046 39.8986 24.0045 39.8986 17.5001 36.9284C17.4582 36.9102 17.4077 36.9166 17.3723 36.9467C17.1735 37.11 16.9651 37.2732 16.7567 37.429C16.6848 37.4827 16.6901 37.5944 16.7686 37.6406C17.759 38.2078 18.7881 38.7073 19.8688 39.1177C19.9396 39.1445 19.9729 39.2251 19.9375 39.2928C19.3542 40.4508 18.6721 41.5508 17.9083 42.5949C17.875 42.6379 17.8202 42.6572 17.7687 42.6411C14.5363 41.6367 11.2438 40.121 7.84395 37.6245C7.81602 37.602 7.79561 37.5676 7.79238 37.5311C7.10059 30.1157 8.51211 22.608 13.6641 14.9102C13.677 14.8897 13.6952 14.8736 13.7178 14.865C16.2519 13.7006 18.9686 12.8444 21.8066 12.3557C21.8582 12.3481 21.9098 12.3718 21.9366 12.4169C22.2879 13.0389 22.6886 13.8349 22.9604 14.4858C25.9521 14.0293 28.991 14.0293 32.0461 14.4858C32.3168 13.8488 32.7035 13.0389 33.0526 12.4169C33.0795 12.3696 33.1311 12.346 33.1826 12.3557C36.0229 12.8455 38.7396 13.7027 41.2726 14.865C41.2951 14.8736 41.3134 14.8897 41.3241 14.9123C45.8122 21.5134 48.0294 28.9599 47.2001 37.5332C47.1969 37.5697 47.1786 37.602 47.1485 37.6245Z" fill="currentColor"/>
  </svg>

export const youtube =
  <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M220.497 297.297L291.503 256.101L220.497 214.703V297.297Z" fill="currentColor"/>
    <path d="M256 0C205.368 0 155.873 15.0141 113.774 43.1438C71.6751 71.2734 38.863 111.255 19.487 158.033C0.11092 204.811 -4.95873 256.284 4.91908 305.943C14.7969 355.602 39.1785 401.217 74.9808 437.019C110.783 472.822 156.398 497.203 206.057 507.081C255.716 516.959 307.189 511.889 353.967 492.513C400.745 473.137 440.727 440.325 468.856 398.226C496.986 356.127 512 306.632 512 256C512 188.105 485.029 122.99 437.019 74.9807C389.01 26.9714 323.896 0 256 0V0ZM390.737 301.305C390.741 308.001 389.426 314.632 386.867 320.819C384.308 327.006 380.555 332.628 375.822 337.364C371.089 342.1 365.469 345.857 359.284 348.421C353.098 350.984 346.468 352.303 339.773 352.303H172.227C165.532 352.303 158.902 350.984 152.717 348.421C146.531 345.857 140.911 342.1 136.178 337.364C131.446 332.628 127.692 327.006 125.133 320.819C122.574 314.632 121.259 308.001 121.263 301.305V210.695C121.259 203.999 122.574 197.368 125.133 191.181C127.692 184.994 131.446 179.372 136.178 174.636C140.911 169.9 146.531 166.143 152.717 163.579C158.902 161.016 165.532 159.697 172.227 159.697H339.773C346.468 159.697 353.098 161.016 359.284 163.579C365.469 166.143 371.089 169.9 375.822 174.636C380.555 179.372 384.308 184.994 386.867 191.181C389.426 197.368 390.741 203.999 390.737 210.695V301.305Z" fill="currentColor"/>
  </svg>

export const facebook =
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.1577 0.673828C12.8484 0.673828 0.4375 13.3359 0.4375 28.9551C0.4375 42.9636 10.4312 54.565 23.5345 56.8116V34.8553H16.8476V26.9541H23.5345V21.1281C23.5345 14.3683 27.5813 10.6846 33.4928 10.6846C36.3241 10.6846 38.7572 10.8998 39.4635 10.9946V18.0604L35.3634 18.0625C32.1493 18.0625 31.5296 19.6204 31.5296 21.9074V26.9501H39.199L38.1987 34.8513H31.5296V57.0005C45.2447 55.2975 55.8776 43.4005 55.8776 28.947C55.8776 13.3359 43.4667 0.673828 28.1577 0.673828Z" fill="currentColor"/>
  </svg>

export const instagram =
  <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.7375 33.754C30.8771 33.754 33.4222 31.1573 33.4222 27.9542C33.4222 24.751 30.8771 22.1543 27.7375 22.1543C24.5979 22.1543 22.0527 24.751 22.0527 27.9542C22.0527 31.1573 24.5979 33.754 27.7375 33.754Z" fill="currentColor"/>
    <path d="M27.7373 0.335938C12.7868 0.335938 0.666992 12.7011 0.666992 27.9542C0.666992 43.2073 12.7868 55.5724 27.7373 55.5724C42.6878 55.5724 54.8076 43.2073 54.8076 27.9542C54.8076 12.7011 42.6878 0.335938 27.7373 0.335938ZM44.4622 34.9736C44.3323 37.5993 43.6091 40.1886 41.7335 42.0818C39.8398 43.993 37.2899 44.7051 34.6914 44.8363H20.7833C18.1845 44.7051 15.6349 43.9934 13.7412 42.0818C11.8655 40.1886 11.1424 37.5993 11.0125 34.9736V20.9347C11.1424 18.309 11.8657 15.7197 13.7412 13.8264C15.6349 11.9153 18.1848 11.2032 20.7833 11.072H34.6913C37.2901 11.2032 39.8397 11.9149 41.7334 13.8264C43.6091 15.7197 44.3322 18.309 44.4621 20.9347L44.4622 34.9736Z" fill="currentColor"/>
    <path d="M34.5157 14.2723C31.128 14.1775 24.3475 14.1775 20.9598 14.2723C19.1968 14.3216 17.1983 14.7694 15.9422 16.1381C14.637 17.5609 14.1438 19.2799 14.0938 21.2029C14.0058 24.578 14.0938 34.7084 14.0938 34.7084C14.151 36.6312 14.637 38.3503 15.9422 39.7731C17.1983 41.1423 19.1968 41.5896 20.9598 41.639C24.3475 41.7338 31.128 41.7338 34.5157 41.639C36.2786 41.5896 38.2772 41.1419 39.5332 39.7731C40.8385 38.3503 41.3317 36.6314 41.3817 34.7084V21.2029C41.3317 19.2799 40.8385 17.5609 39.5332 16.1381C38.2767 14.7689 36.2782 14.3216 34.5157 14.2723ZM27.7373 36.9453C25.9946 36.9453 24.291 36.4181 22.842 35.4303C21.3929 34.4425 20.2636 33.0385 19.5967 31.3958C18.9297 29.7532 18.7552 27.9457 19.0952 26.2018C19.4352 24.458 20.2744 22.8562 21.5067 21.5989C22.739 20.3417 24.309 19.4855 26.0183 19.1386C27.7275 18.7918 29.4992 18.9698 31.1093 19.6502C32.7193 20.3306 34.0955 21.4829 35.0637 22.9612C36.0319 24.4396 36.5487 26.1776 36.5487 27.9556C36.5487 30.3398 35.6203 32.6264 33.9679 34.3123C32.3154 35.9982 30.0742 36.9453 27.7373 36.9453ZM36.5862 20.8215C36.2376 20.8214 35.897 20.7159 35.6072 20.5183C35.3175 20.3207 35.0916 20.0399 34.9583 19.7113C34.825 19.3828 34.7902 19.0213 34.8582 18.6725C34.9263 18.3238 35.0941 18.0035 35.3406 17.7521C35.5871 17.5007 35.9011 17.3295 36.2429 17.2602C36.5848 17.1908 36.9391 17.2264 37.2611 17.3625C37.5831 17.4986 37.8583 17.7291 38.0519 18.0247C38.2455 18.3204 38.3489 18.668 38.3489 19.0236C38.3489 19.2597 38.3033 19.4936 38.2147 19.7117C38.1261 19.9299 37.9962 20.1281 37.8326 20.2951C37.6689 20.462 37.4745 20.5945 37.2607 20.6848C37.0468 20.7751 36.8176 20.8216 36.5862 20.8215Z" fill="currentColor"/>
  </svg>

export const twitter =
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.3177 0C13.0107 0 0.597656 12.6643 0.597656 28.2811C0.597656 43.8979 13.0107 56.5621 28.3177 56.5621C43.6247 56.5621 56.0377 43.8979 56.0377 28.2811C56.0377 12.6643 43.6247 0 28.3177 0ZM40.9743 22.0506C40.9866 22.3289 40.9925 22.6085 40.9925 22.8895C40.9925 31.4671 34.5929 41.3583 22.8897 41.3587H22.8901H22.8897C19.2965 41.3587 15.9529 40.2842 13.1371 38.4428C13.635 38.5028 14.1417 38.5326 14.6548 38.5326C17.6359 38.5326 20.3793 37.4952 22.5572 35.754C19.7719 35.7013 17.4236 33.8246 16.6131 31.2453C17.001 31.3212 17.3999 31.3627 17.8089 31.3627C18.3896 31.3627 18.9522 31.2828 19.4868 31.1339C16.5755 30.5393 14.3824 27.9143 14.3824 24.771C14.3824 24.7416 14.3824 24.7153 14.3832 24.6881C15.2406 25.1744 16.2211 25.467 17.265 25.4998C15.5566 24.3368 14.4336 22.3488 14.4336 20.0966C14.4336 18.9073 14.7487 17.793 15.2952 16.8337C18.4328 20.7616 23.1219 23.3447 28.4099 23.6162C28.3008 23.1406 28.2445 22.6452 28.2445 22.136C28.2445 18.5525 31.0941 15.6453 34.6077 15.6453C36.4379 15.6453 38.0909 16.4346 39.252 17.6964C40.7015 17.4047 42.0627 16.8644 43.2922 16.1208C42.8164 17.636 41.808 18.9073 40.4943 19.7112C41.7814 19.5541 43.008 19.2059 44.1479 18.6889C43.2965 19.9909 42.2166 21.1344 40.9743 22.0506Z" fill="currentColor"
    />
  </svg>

export const linkedIn =
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.8978 0C12.5908 0 0.177734 12.6643 0.177734 28.2811C0.177734 43.8979 12.5908 56.5621 27.8978 56.5621C43.2048 56.5621 55.6178 43.8979 55.6178 28.2811C55.6178 12.6643 43.2048 0 27.8978 0ZM19.8426 42.753H13.0916V22.0311H19.8426V42.753ZM16.4673 19.2016H16.4233C14.1579 19.2016 12.6927 17.6105 12.6927 15.622C12.6927 13.5886 14.2027 12.0415 16.5121 12.0415C18.8216 12.0415 20.2428 13.5886 20.2868 15.622C20.2868 17.6105 18.8216 19.2016 16.4673 19.2016ZM44.1857 42.753H37.4354V31.6673C37.4354 28.8813 36.4579 26.9813 34.0152 26.9813C32.1503 26.9813 31.0396 28.2629 30.5515 29.5002C30.373 29.9429 30.3294 30.5617 30.3294 31.181V42.753H23.5788C23.5788 42.753 23.6672 23.9752 23.5788 22.0311H30.3294V24.9652C31.2266 23.5532 32.8318 21.5448 36.4135 21.5448C40.8552 21.5448 44.1857 24.5064 44.1857 30.8711V42.753Z" fill="currentColor"/>
  </svg>
