import React, { FC } from 'react'
import classNames from 'classnames'

import { TrackLink } from 'components'

import { trackClick } from 'core/utils'

import cookie from './assets/cookie.svg'

import { Content } from '../content/Content'
import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  acceptCookies: () => void
}

export const CookieModal: FC<ComponentProps> = ({ acceptCookies }) => {
  return (
    <Content
      mainWrapperClassName={classes.wrapper}
      className={classes.content}
    >
      <div className={classes.textBlock}>
        <img
          src={cookie}
          width={41}
          height={40}
          alt="cookie"
        />

        <Text type={TextName.Subtext}>
          We use cookies to enhance your browsing experience,
          personalise content and analyse our traffic.
          Learn&nbsp;more&nbsp;in&nbsp;our&nbsp;
          <TrackLink
            to="/cookie-policy"
            trackName="Cookie_policy"
            className={classes.link}
          >
            Cookie&nbsp;Policy
          </TrackLink>
          .
        </Text>
      </div>

      <div className={classes.buttonsBlock}>
        <button
          onClick={() => {
            trackClick('Accept_cookies')
            acceptCookies()
          }}
          className={classNames(
            classes.button,
            classes.accept,
          )}
        >
          Accept
        </button>

        { /* TODO: implement Reject functionality in JSW-578 */ }
      </div>
    </Content>
  )
}
