import React, { FC } from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'
import { ActionButtonLearnMore } from 'sdk/action-buttons/ActionButtonLearnMore'

import classes from './style.module.scss'

interface ComponentProps {
  title: string
  description: string
  image: string
  buttonLink?: string
  textFirst?: boolean
}

export const ImageTextBlock: FC<ComponentProps> = (props) => {
  const {
    title,
    description,
    image,
    buttonLink,
    textFirst,
  } = props

  return (
    <div className={classNames(classes.container, { [classes.textFirst]: textFirst })}>
      <div className={classes.imageBlock}>
        <img
          src={image}
          width={589}
          height={589}
          alt="feature-image"
        />
      </div>

      <div className={classes.textBlock}>
        <Text
          type={TextName.H2}
          className={classes.title}
        >
          { title }
        </Text>

        <Text type={TextName.Text}>
          { description }
        </Text>

        { buttonLink && (
          <ActionButtonLearnMore
            className={classes.buttonLink}
            href={buttonLink}
          />
        ) }
      </div>
    </div>
  )
}

ImageTextBlock.defaultProps = {
  textFirst: false,
}
