import React, { FC, PropsWithChildren } from 'react'

import { CloseButton } from 'sdk/components/buttons/close-button/CloseButton'
import { Text, TextName } from 'sdk/text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  title: string
  onClose(): void
  className?: string
}

export const FormPopup: FC<PropsWithChildren<ComponentProps>> = ({ title, children, onClose }) => {
  return (
    <div className={classes.popup}>
      <div className={classes.titleBlock}>
        <Text
          type={TextName.H3}
          className={classes.title}
        >
          { title }
        </Text>

        <CloseButton
          className={classes.close}
          onClick={onClose}
        />
      </div>

      { children }
    </div>
  )
}
