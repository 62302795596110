import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'redux/store'
import { logoutUser } from 'redux/userReducer'
import { closePopup } from 'redux/popupReducer'
import { CloseButton } from 'sdk/components'
import { Button } from 'sdk/button/Button'
import { ActionButtonLogOut } from 'sdk/action-buttons/log-out/ActionButtonLogOut'

import { apiRequest, HttpMethod, trackClick } from 'core/utils'
import { useKeyboardAction } from 'core/hooks'

import classes from './style.module.scss'

export const SignOut: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const logout = (): void => {
    dispatch(closePopup())
    apiRequest('/api/users/sign_out.json', {}, HttpMethod.Delete)
    navigate('/', { replace: true })
    dispatch(logoutUser())
  }

  const close = (): void => {
    dispatch(closePopup())
  }

  useKeyboardAction(close)

  return (
    <div className={classes.container}>
      <div className={classes.headingBlock}>
        <h2>Sign Out</h2>

        <CloseButton
          onClick={close}
          className={classes.close}
        />
      </div>

      <div className={classes.contentBlock}>
        <p>Are you sure you want to sign out?</p>
      </div>

      <div className={classes.buttonsBlock}>
        <Button
          onClick={() => {
            trackClick('Cancel_log_out')
            close()
          }}
          className={classes.button}
        >
          Close
        </Button>
        <ActionButtonLogOut
          onClick={() => {
            trackClick('Submit_log_out')
            logout()
          }}
          className={classes.button}
        />
      </div>
    </div>
  )
}
