import * as React from 'react'

import { SimpleTitleButtonHero } from 'sdk/sections'
import { ActionButtonLearnMore } from 'sdk/action-buttons/ActionButtonLearnMore'

import { scrollToId } from 'core/utils'
import { SectionId } from 'core/interfaces'

import heroImage from './assets/products-hero-image.jpeg'

export const Hero: React.FC = () => (
  <SimpleTitleButtonHero
    title="The Power of GPU in Partner Products"
    description="Unlock your GPU to power your music and audio production workflows"
    actionButton={(
      <ActionButtonLearnMore
        onClick={() => scrollToId(SectionId.FlagshipProduct, 1.1)}
      />
    )}
    imageLink={heroImage}
    videoLink="https://eap-spaces.fra1.cdn.digitaloceanspaces.com/packs/products-hero-video.webm"
  />
)
