import React, { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Content, TextInput } from 'sdk/components'
import { Text, TextName } from 'sdk'
import underglow from 'shared/assets/underglow.png'

import { apiRequest, HttpMethod, trackClick } from 'core/utils'

import { NotifyButton } from './notify-button/NotifyButton'
import newsletter from './assets/newsletter.jpeg'

import classes from './style.module.scss'

interface Inputs {
  email: string
}

export const JoinOurNewsletter: FC = () => {
  const { register, handleSubmit, reset, setError, formState: { errors, isSubmitting }, watch } = useForm<Inputs>()

  const [emailSent, setEmailSent] = React.useState<boolean>(false)
  const onSubmit: SubmitHandler<Inputs> = (lead) => apiRequest<void>('/api/leads', { lead }, HttpMethod.Post)
    .then(() => {
      setEmailSent(true)
      reset()
    })
    .catch((errors) => {
      errors.errors?.email && setError('email', { message: errors.errors.email })
    })

  const emailInput = watch('email')

  return (
    <Content
      mainWrapperClassName={classes.wrapper}
      className={classes.content}
    >
      <div className={classes.textBlock}>
        <Text type={TextName.H1}>
          Join Our Newsletter
        </Text>

        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            {...register('email')}
            className={classes.input}
            placeholder="Enter your e-mail address"
          />
          { errors.email && (
            <span className={classes.error}>
              { errors.email?.message }
            </span>
          ) }
          <NotifyButton
            onClick={() => trackClick('Newsfeed_subscribe')}
            disabled={!emailInput || isSubmitting || emailSent}
            notified={emailSent}
          />
        </form>

        <Text
          type={TextName.Subtext}
          className={classes.agreement}
        >
          Get email updates and promotions directly from GPU Audio!
          Sign up and get the latest news, exclusive discounts, and
          free giveaways.
          <br />
          <br />
          By clicking this button, you agree to the terms of use of the
          site and give consent to the processing of personal data
          and receiving&nbsp;e-mail updates&nbsp;and promotions from GPU Audio
        </Text>
      </div>
      <div className={classes.imageContainer}>
        <img
          className={classes.underglow}
          src={underglow}
          width={1860}
          height={1658}
          alt="underglow"
        />
        <img
          className={classes.newsletter}
          src={newsletter}
          alt="newsletter"
        />
      </div>
    </Content>
  )
}
