import React, { forwardRef, InputHTMLAttributes } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  isInvalid?: boolean
}

export const TextInput = forwardRef<
  HTMLInputElement,
  ComponentProps
>((props: ComponentProps, ref) => {
  const {
    className,
    isInvalid,
    ...rest
  } = props

  return (
    <input
      ref={ref}
      className={classNames(
        classes.input,
        { [classes.isInvalid]: isInvalid },
        className,
      )}
      {...rest}
    />
  )
})

TextInput.displayName = 'TextInput'
