import * as React from 'react'
import classNames from 'classnames'
import { useState } from 'react'
import { useLoaderData } from 'react-router-dom'

import { NewsfeedMediumCard } from 'pages/newsfeed/components/newsfeed-medium-card/NewsfeedMediumCard'
import { Content } from 'sdk/components'
import { NewsfeedCard } from 'pages/newsfeed/components/newsfeed-card/NewsfeedCard'
import { FilterItem } from 'pages/newsfeed/components/filter-item/FilterItem'
import { JoinOurNewsletter } from 'sdk/sections'

import { NewsfeedEntry, NewsfeedRecordType } from 'core/interfaces'

import { NewsfeedBigCard } from '../components/newsfeed-big-card/NewsfeedBigCard'

import classes from './style.module.scss'

export const NewsfeedIndexPage: React.FC = () => {
  const newsfeed: NewsfeedEntry[] = useLoaderData() as NewsfeedEntry[]
  const [activeFilter, setActiveFilter] = useState<NewsfeedRecordType | 'All'>('All')
  const BIG_CARD_NEWS_INDEX = 0
  const MEDIUM_CARD_NEWS_START_INDEX = 1
  const REGULAR_NEWS_START_INDEX = 3

  const filterRegularNewsfeed = (filterType: NewsfeedRecordType | 'All'): void => {
    setActiveFilter(filterType)
  }

  if (!newsfeed || newsfeed?.length === 0) {
    return <div className={classes.empty}>No news or events.</div>
  }

  let filteredRegularNewsfeed: NewsfeedEntry[] = []
  if (activeFilter === 'All') {
    filteredRegularNewsfeed = newsfeed?.slice(REGULAR_NEWS_START_INDEX)
  } else {
    filteredRegularNewsfeed = newsfeed?.slice(REGULAR_NEWS_START_INDEX).filter((entry) => entry.type === activeFilter)
  }

  return (
    <>
      {
        newsfeed.length > BIG_CARD_NEWS_INDEX ? (
          <NewsfeedBigCard entry={newsfeed[BIG_CARD_NEWS_INDEX]} showLearnMore />
        ) : undefined
      }

      { newsfeed.length > MEDIUM_CARD_NEWS_START_INDEX && (
        <Content className={classNames(classes.newsfeedContainer, classes.newsfeedMediumCardContainer)}>
          { newsfeed
            .slice(MEDIUM_CARD_NEWS_START_INDEX, REGULAR_NEWS_START_INDEX)
            .map((entry, index) => (
              <NewsfeedMediumCard
                key={index}
                entry={entry}
              />
            )) }
        </Content>
      ) }

      <Content className={classes.divider}>
        <hr />
      </Content>

      { filteredRegularNewsfeed.length > 0 ? (
        <Content className={classNames(classes.newsfeedContainer, classes.newsfeedCardContainer)}>
          <div className={classes.filters}>
            <FilterItem
              filterType="All"
              activeFilter={activeFilter}
              label="View All"
              onFilterChange={filterRegularNewsfeed}
            />
            <FilterItem
              filterType={NewsfeedRecordType.Event}
              activeFilter={activeFilter}
              label="Events"
              onFilterChange={filterRegularNewsfeed}
            />
            <FilterItem
              filterType={NewsfeedRecordType.Article}
              activeFilter={activeFilter}
              label="News"
              onFilterChange={filterRegularNewsfeed}
            />
          </div>

          { filteredRegularNewsfeed.map((entry, index) => <NewsfeedCard key={index} entry={entry} />) }
        </Content>
      ) : null }

      <JoinOurNewsletter />
    </>
  )
}
