import * as React from 'react'

import { Button } from 'sdk/button/Button'

import icon from './assets/discover-hovered.svg'

interface ComponentProps {
  href: string
}

export const ActionButtonDiscover: React.FC<ComponentProps> = ({ href }) => {
  return (
    <Button
      href={href}
      as="link"
      target="_blank"
      iconHovered={icon}
    >
      Discover
    </Button>
  )
}
