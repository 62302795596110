import React from 'react'
import { useLoaderData } from 'react-router-dom'

import { UnsafeHTML } from 'components'
import { Content } from 'sdk/components'

import { StaticPageRecord } from 'core/interfaces'
import { useScrollToHash } from 'core/utils'

import classes from './style.module.scss'

export const StaticPage: React.FC = () => {
  useScrollToHash()
  const { content } = useLoaderData() as StaticPageRecord

  return (
    <Content className={classes.content}>
      <UnsafeHTML>{ content }</UnsafeHTML>
    </Content>
  )
}
