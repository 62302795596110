export const routes = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Partner Products',
    path: '/products',
  },
  {
    title: 'News & Events',
    path: '/newsfeed',
  },
]
