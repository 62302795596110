import * as React from 'react'
import { Link } from 'react-router-dom'

import { JobRecord } from 'core/interfaces'
import { jobLink } from 'core/utils'

import classes from './style.module.scss'

interface ComponentProps {
  job: JobRecord
}

export const JobItem: React.FC<ComponentProps> = ({ job }) => (
  <div className={classes.job}>
    <h2 className={classes.title}>
      { job.title }
    </h2>

    <div className={classes.buttonsBlock}>
      <Link
        className={classes.button}
        to={jobLink(job)}
      >
        View More
      </Link>
    </div>
  </div>
)
