import * as React from 'react'

import { Text, TextName } from 'sdk'
import { useAppSelector } from 'redux/store'
import { Content } from 'sdk/components'

import VSLLogo from './assets/VSL.svg'
import MNTRALogo from './assets/MNTRA.svg'
import AMLogo from './assets/AM.svg'

import classes from './style.module.scss'

export const IndustryLeaders: React.FC = () => {
  const links = useAppSelector((state) => state.settings.links)

  return (
    <Content className={classes.container}>
      <Text type={TextName.H2} className={classes.title}>
        Industry leaders are supercharging their tech with GPU Audio
      </Text>

      <div className={classes.logos}>
        <a
          className={classes.logo}
          href={links?.mir_pro_3d}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={VSLLogo}
            alt="VSL Logo"
            width={360}
            height={200}
          />
        </a>
        <a
          className={classes.logo}
          href={links?.mntra_living_sky}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={MNTRALogo}
            alt="MNTRA Logo"
            width={360}
            height={200}
          />
        </a>
        <a
          className={classes.logo}
          href="https://audiomodeling.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={AMLogo}
            alt="AM Logo"
            width={360}
            height={200}
          />
        </a>
      </div>

      <div className={classes.promo}>
        <div className={classes.cpu}>
          <Text type={TextName.Text}>
            Up to
            <span className={classes.performance}>
              10X
            </span>
          </Text>
          CPU Load Decrease
        </div>
        <div>
          <Text
            type={TextName.Text}
            style={{ marginBottom: '1em' }}
          >
            “The emergence of GPU Audio has unveiled new creative possibilities
            for us. It’s allowing us to create tools that make spatialization
            as dynamic and expressive as any instrument in a musician’s hands.
            This cutting-edge technology is redefining audio production. It
            offers creators the precision and versatility to add narrative
            depths in music, a feat that used to demand extensive post-production work.”
          </Text>
          <Text type={TextName.TextBold}>
            Brian D&apos;Oliveira, CEO @ MNTRA.io
          </Text>
        </div>
      </div>
    </Content>
  )
}
