import * as React from 'react'
import classNames from 'classnames'

import { NewsfeedRecordType } from 'core/interfaces'

import classes from './style.module.scss'

export interface ComponentProps {
  filterType: NewsfeedRecordType | 'All';
  activeFilter: NewsfeedRecordType | 'All';
  label: string;
  onFilterChange: (filterType: NewsfeedRecordType | 'All') => void;
}

export const FilterItem: React.FC<ComponentProps> = (props) => {
  const { filterType, activeFilter, label, onFilterChange } = props

  return (
    <span
      className={classNames(
        classes.item,
        activeFilter === filterType && classes.active,
      )}
      onClick={() => onFilterChange(filterType)}
    >{ label }</span>
  )
}