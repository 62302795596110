import React, { forwardRef, InputHTMLAttributes } from 'react'
import classNames from 'classnames'

import { Text, TextName } from 'sdk'

import { TextInput } from '../text-input/TextInput'

import classes from './style.module.scss'

export const enum FormFieldSize {
  small = 'small',
  normal = 'normal'
}

interface ComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  fieldSize?: FormFieldSize
  label: string
  error?: string
}

export const FormField = forwardRef<
  HTMLInputElement,
  ComponentProps
>((props: ComponentProps, ref) => {
  const {
    fieldSize,
    label,
    error,
    className,
    ...rest
  } = props

  return (
    <div
      className={classNames(
        classes.field,
        classes[fieldSize!],
        className,
      )}
    >
      <label
        htmlFor={rest.name}
        className={classes.label}
      >
        <Text
          type={fieldSize === FormFieldSize.normal ? TextName.Text : TextName.Subtext}
        >
          { label }
        </Text>
      </label>
      <TextInput
        className={classes.input}
        ref={ref}
        id={rest.name}
        isInvalid={!!error}
        {...rest}
      />
      { error && (
        <span
          className={classes.error}
        >
          { error }
        </span>
      ) }
    </div>
  )
})

FormField.defaultProps = {
  fieldSize: FormFieldSize.normal,
}
FormField.displayName = 'TextInput'
