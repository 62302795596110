import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'

export interface AlertState {
  notice: string | null
}

const initialState: AlertState = {
  notice: null,
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<string>) => {
      state.notice = action.payload
    },
    closeAlert: (state) => {
      state.notice = null
    },
  },
})

export const { showAlert, closeAlert } = alertSlice.actions
export default alertSlice.reducer

// TODO: quick hake until thunks are implemented
export const showAlertWithDelay = (alert: string, dispatch: (arg0: unknown) => void): void => {
  dispatch(showAlert(alert))
  setTimeout(() => {
    dispatch(closeAlert())
  }, 10_000)
}
