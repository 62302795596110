import * as React from 'react'
import * as Sentry from '@sentry/react'

import { useAppDispatch, useAppSelector } from 'redux/store'
import { showAlertWithDelay } from 'redux/alertReducer'
import { CookieModal, FlashModal } from 'sdk/components'
import { PopupContainerWithState } from 'pages/popup-container/PopupContainerWithState'

import {
  apiRequest,
  getCookie,
  navigationEvent,
  processBENotification,
  setCookiesAccepted,
  trackEvent,
  trackUid,
  usePageTitles,
} from 'core/utils'
import './styles.scss'

interface ComponentProps {
  children: React.ReactNode
}

export const App: React.FC<ComponentProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { user: currentUser, loggedIn } = useAppSelector((state) => state.user)
  const { debug, flash } = useAppSelector((state) => state.settings)

  usePageTitles()

  React.useEffect(() => {
    const sessionTimestamp = Date.now().toString()

    trackEvent('Session_Start', sessionTimestamp)

    if (currentUser) {
      processBENotification(dispatch)
      Sentry.setUser({ id: currentUser.uid, email: currentUser.email })
    }

    apiRequest<void>('/api/a/events', {
      category: 'session',
      action: 'Session_Start',
      label: sessionTimestamp,
    })
    if (currentUser) {
      trackUid(currentUser)
    }

    if (flash.notice) {
      // FIXME: it works without argument
      showAlertWithDelay(flash.notice, dispatch)
    }

    navigationEvent(loggedIn)
  }, [])

  const [cookieModalActive, setCookieModalActive] = React.useState<boolean>(getCookie('cookie-banner') !== 'accept')

  const acceptCookies = React.useCallback(() => {
    setCookieModalActive(false)
    setCookiesAccepted()
  }, [])

  if (debug) {
    // eslint-disable-next-line no-console
    console.log('Start in debug mode')
  }

  return (
    <>
      { children }

      <FlashModal />

      { cookieModalActive ? (
        <CookieModal acceptCookies={acceptCookies} />
      ) : null }

      <PopupContainerWithState />
    </>
  )
}
