import React, { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useAppDispatch } from 'redux/store'
import { closePopup } from 'redux/popupReducer'
import { showAlertWithDelay } from 'redux/alertReducer'
import { PopupForm, FormField } from 'sdk/components'
import { ActionButtonEnter } from 'sdk/action-buttons/ActionButtonEnter'

import { apiRequest, ContentType, HttpMethod } from 'core/utils'

interface Inputs {
  email: string
  content: string
  profile_link: string
  cv: (string | Blob)[]
}

const fields: (keyof Inputs)[] = ['email', 'content', 'profile_link', 'cv']

export const ApplyToJobForm: FC = () => {
  const { register, handleSubmit, reset, setError, formState: { errors, isSubmitting } } = useForm<Inputs>()
  const dispatch = useAppDispatch()
  const onSubmit: SubmitHandler<Inputs> = (job_application) => {
    const formData = new FormData()

    Object.entries({ ...job_application, cv: job_application.cv && job_application.cv[0] }).forEach(([field, data]) => {
      if (data) {
        formData.set(`job_application[${field}]`, data)
      }
    })

    return apiRequest<unknown>('/api/job_applications', formData, HttpMethod.Post, ContentType.FormData)
      .then(() => {
        reset()
        dispatch(closePopup())
        showAlertWithDelay('Thanks for your application!', dispatch)
      })
      .catch((errorResponse) => {
        if (errorResponse.errors) {
          fields.forEach((field) => {
            errorResponse.errors[field] && setError(field, { message: errorResponse.errors[field] })
          })
        }
      })
  }

  console.error('have errors', errors)

  return (
    <PopupForm
      onSubmit={handleSubmit(onSubmit)}
      baseError=''
    >
      <FormField
        {...register('email')}
        label="E-mail: *"
        placeholder="Enter your e-mail"
        error={errors.email?.message}
        autoComplete="on"
        type="email"
      />

      <FormField
        {...register('content')}
        label="Message:"
        placeholder="Write your message"
        error={errors.content?.message}
        autoComplete="on"
        type="text"
      />

      <FormField
        {...register('profile_link')}
        label="Link to CV"
        placeholder="Enter link to your CV"
        error={errors.profile_link?.message}
        autoComplete="on"
        type="text"
      />

      <FormField
        {...register('cv')}
        error={errors.cv?.message}
        label="CV (PDF)"
        accept="application/pdf"
        type="file"
      />

      <ActionButtonEnter
        disabled={isSubmitting}
      >
        Submit
      </ActionButtonEnter>
    </PopupForm>
  )
}
