import { SectionId } from 'core/interfaces'

export const scrollToId = (id: SectionId, divider = 1): null => {
  if (document.getElementById(id) !== null) {
    window.scrollTo({
      top: document.getElementById(id)!.offsetTop / divider,
      left: 0,
      behavior: 'smooth',
    })
  }

  return null
}
