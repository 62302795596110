import * as React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { Text, TextName } from 'sdk'
import { UnsafeHTML } from 'components'
import { JoinOurNewsletter } from 'sdk/sections'
import { Content } from 'sdk/components'
import { NewsfeedBigCard } from 'pages/newsfeed/components/newsfeed-big-card/NewsfeedBigCard'
import { MoreItems } from 'pages/newsfeed/components/more-items/MoreItems'

import { NewsfeedFullEntry } from 'core/interfaces'
import { setTitle } from 'core/utils'

import classes from './style.module.scss'

export const NewsfeedItemPage: React.FC = () => {
  const item = useLoaderData() as NewsfeedFullEntry
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!item?.id) {
      navigate('/not-found')
      return
    }
    setTitle(item.title)
  }, [])

  if (!item?.id) {
    return null
  }

  return (
    <>
      <NewsfeedBigCard entry={item} showLearnMore={false} />

      <Content className={classes.container}>
        <div>
          <Text className={classes.header} type={TextName.H3}>
            { item.title }
          </Text>

          <UnsafeHTML>
            { item.content }
          </UnsafeHTML>
        </div>

        <hr />

        { item.related_items.length > 0 ? (
          <MoreItems relatedItems={item.related_items} />
        ) : null }
      </Content>

      <JoinOurNewsletter />
    </>
  )
}
