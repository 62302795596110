import * as React from 'react'

interface YoutubeEmbedProps {
  embedId: string
  className?: string
}

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedId, className }) => (
  <div className={className}>
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
)
