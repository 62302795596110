import * as React from 'react'

import { SocialNetworks } from 'components/social-networks/SocialNetworks'
import { TrackALink } from 'components/track-link/TrackALink'
import { closePopup } from 'redux/popupReducer'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { CloseButton } from 'sdk/components'

import { useKeyboardAction } from 'core/hooks'

import './style.scss'

{ /* eslint-disable max-len */ }
const discord =
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.4814 29.0097C24.4814 31.2183 22.8991 33.0154 20.9097 33.0154C18.9514 33.0154 17.3379 31.2183 17.3379 29.0097C17.3379 26.8021 18.9202 25.0039 20.9097 25.0039C22.9142 25.0039 24.5126 26.8183 24.4814 29.0097Z" fill="currentColor"/>
    <path d="M37.6865 29.0097C37.6865 31.2183 36.1203 33.0154 34.1147 33.0154C32.1564 33.0154 30.543 31.2183 30.543 29.0097C30.543 26.8021 32.1253 25.0039 34.1147 25.0039C36.1203 25.0039 37.7177 26.8183 37.6865 29.0097Z" fill="currentColor"/>
    <path d="M27.5 0C12.3127 0 0 12.3127 0 27.5C0 42.6873 12.3127 55 27.5 55C42.6873 55 55 42.6873 55 27.5C55 12.3127 42.6873 0 27.5 0ZM47.1485 37.6245C43.7497 40.121 40.4562 41.6367 37.2088 42.6411C37.1572 42.6572 37.1014 42.6379 37.0691 42.5938C36.3193 41.5497 35.6383 40.4486 35.041 39.2928C35.0066 39.2251 35.0378 39.1435 35.1087 39.1166C36.1904 38.7073 37.2195 38.2186 38.21 37.6406C38.2884 37.5944 38.2927 37.4827 38.2207 37.429C38.0112 37.2732 37.8028 37.1089 37.6041 36.9445C37.5665 36.9145 37.516 36.908 37.4741 36.9284C31.046 39.8986 24.0045 39.8986 17.5001 36.9284C17.4582 36.9102 17.4077 36.9166 17.3723 36.9467C17.1735 37.11 16.9651 37.2732 16.7567 37.429C16.6848 37.4827 16.6901 37.5944 16.7686 37.6406C17.759 38.2078 18.7881 38.7073 19.8688 39.1177C19.9396 39.1445 19.9729 39.2251 19.9375 39.2928C19.3542 40.4508 18.6721 41.5508 17.9083 42.5949C17.875 42.6379 17.8202 42.6572 17.7687 42.6411C14.5363 41.6367 11.2438 40.121 7.84395 37.6245C7.81602 37.602 7.79561 37.5676 7.79238 37.5311C7.10059 30.1157 8.51211 22.608 13.6641 14.9102C13.677 14.8897 13.6952 14.8736 13.7178 14.865C16.2519 13.7006 18.9686 12.8444 21.8066 12.3557C21.8582 12.3481 21.9098 12.3718 21.9366 12.4169C22.2879 13.0389 22.6886 13.8349 22.9604 14.4858C25.9521 14.0293 28.991 14.0293 32.0461 14.4858C32.3168 13.8488 32.7035 13.0389 33.0526 12.4169C33.0795 12.3696 33.1311 12.346 33.1826 12.3557C36.0229 12.8455 38.7396 13.7027 41.2726 14.865C41.2951 14.8736 41.3134 14.8897 41.3241 14.9123C45.8122 21.5134 48.0294 28.9599 47.2001 37.5332C47.1969 37.5697 47.1786 37.602 47.1485 37.6245Z" fill="currentColor"/>
  </svg>

// FIXME: move svg to the static assets

export const DownloadOverlay: React.FC = () => {
  const { links } = useAppSelector((state) => state.settings)
  const dispatch = useAppDispatch()

  const close = (): void => {
    dispatch(closePopup())
  }

  useKeyboardAction(close)

  return (
    <div className="download-overlay">
      <CloseButton className="download-overlay__close" onClick={close} />

      <h3 className="download-overlay__title">
        Thanks for your download!
      </h3>

      <p className="download-overlay__invitation">
        Join the GPU Audio Early Access Community to stay tuned for updates and share your experience
      </p>

      <div className="download-overlay__buttons">
        <TrackALink
          trackName="Discord"
          href={links!.discord}
          target="_blank"
          rel="noreferrer"
          className="download-overlay__discord-button"
        >
          <div
            className="download-overlay__discord-icon"
          >
            { discord }
          </div>
          Our discord server
        </TrackALink>

        <SocialNetworks
          downloadOverlay
          className="download-overlay__social-network-links"
        />
      </div>
    </div>
  )
}

