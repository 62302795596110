import * as React from 'react'

import { useAppDispatch } from 'redux/store'
import { closePopup } from 'redux/popupReducer'

import xMark from './assets/xmark.png'
import flyingLaptops from './assets/flying-laptops.png'

import classes from './style.module.scss'

interface ComponentProps {
  title: string
  content: React.ReactNode
}

export const SimplePopup: React.FC<ComponentProps> = ({ title, content }) => {
  const dispatch = useAppDispatch()

  return (
    <div className={classes.container}>
      <button
        className={classes.closeButton}
        onClick={() => dispatch(closePopup())}
      >
        <img src={xMark} />
      </button>
      <h2 className={classes.title}>{ title }</h2>
      <section className={classes.content}>{ content }</section>
      <img className={classes.flyingLaptops} src={flyingLaptops} />
    </div>
  )
}
