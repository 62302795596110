import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'

import { CloseButton } from 'sdk/components/buttons/close-button/CloseButton'

import classes from './style.module.scss'

type AlertVariant = 'warning' | 'error'

interface ComponentProps {
  children: ReactNode
  onClose?: () => void
  className?: string
  variant?: AlertVariant
  show?: boolean
}

export const Alert: FC<ComponentProps> = (props) => {
  const {
    children,
    variant,
    show,
    onClose,
    className,
  } = props

  return (show ? (
    <div
      className={classNames(
        classes.alert,
        classes[variant!],
        { [classes.withClose]: onClose },
        className,
      )}
      onClick={onClose}
    >
      { children }

      { onClose && (
        <CloseButton
          onClick={onClose}
          className={classes.closeButton}
        />
      ) }
    </div>
  ) : null)
}

Alert.defaultProps = {
  onClose: undefined,
  className: undefined,
  variant: 'warning',
  show: true,
}
