import React, { FC } from 'react'

import { FormPopup } from 'sdk/components'
import { useAppDispatch } from 'redux/store'
import { closePopup } from 'redux/popupReducer'

import { ApplyToJobForm } from './form/ApplyToJobForm'

export const ApplyToJob: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <FormPopup title="Apply to Job" onClose={() => dispatch(closePopup())}>
      <ApplyToJobForm />
    </FormPopup>
  )
}
