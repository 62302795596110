import * as React from 'react'

import { FlagshipProduct } from 'sdk/sections'

import { Hero } from './sections/hero/Hero'
import { PartnerProducts } from './sections/partner-products/PartnerProducts'
import { Support } from './sections/support/Support'

export const ProductsPage: React.FC = () => {
  return (
    <>
      <Hero />
      <FlagshipProduct />
      <PartnerProducts />
      <Support />
    </>
  )
}
