import * as React from 'react'

import { Text, TextName } from 'sdk'
import { Content } from 'sdk/components'

import { SectionId } from 'core/interfaces'

import gaugeIcon from './assets/gauge.svg'
import circuitIcon from './assets/circuit.svg'
import lightningIcon from './assets/lightning.svg'
import scaleIcon from './assets/scale.svg'
import gpuImage from './assets/gpu.jpeg'
import gpuVideo from './assets/gpu-video.webm'

import classes from './style.module.scss'

export const features = [
  {
    icon: gaugeIcon,
    alt: 'gauge icon',
    description: 'Real-time high performance DSP',
  },
  {
    icon: circuitIcon,
    alt: 'circuit icon',
    description: 'Powerful parallel processing, without adding latency',
  },
  {
    icon: lightningIcon,
    alt: 'lightning icon',
    description: 'Experience 1ms latency, regardless of channels count or effects',
  },
  {
    icon: scaleIcon,
    alt: 'scale icon',
    description: 'Networkability and scalability',
  },
]

interface ComponentProps {
  icon: string
  alt: string
  description: string
}

const FeatureItem: React.FC<ComponentProps> = ({
  icon,
  alt,
  description,
}) => (
  <div className={classes.item}>
    <img
      src={icon}
      alt={alt}
      className={classes.itemIcon}
    />
    <Text type={TextName.Text}>
      { description }
    </Text>
  </div>
)

export const NewEra: React.FC = () => {
  return (
    <Content
      id={SectionId.NewEra}
      className={classes.content}
    >
      <Text type={TextName.H2} className={classes.title}>
        The New Era of Audio is GPU Powered
      </Text>

      <Text type={TextName.Text} className={classes.subtitle}>
        The world&apos;s first novel technology
        that enables real-time audio DSP on GPUs
      </Text>

      <div className={classes.gpuFeaturesWrapper}>
        <div className={classes.gpuBlock}>
          <img
            src={gpuImage}
            alt="gpu-image"
            className={classes.gpuItem}
          />
          <video
            loop
            autoPlay
            muted
            playsInline
            className={classes.gpuItem}
          >
            <source
              src={gpuVideo}
              type="video/webm"
            />
          </video>
        </div>

        <div className={classes.features}>
          { features.map((feature, index) => (
            <FeatureItem
              key={index}
              icon={feature.icon}
              alt={feature.alt}
              description={feature.description}
            />
          )) }
        </div>
      </div>
    </Content>
  )
}
