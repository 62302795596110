import * as React from 'react'

import { Content } from 'sdk/components'

import classes from './style.module.scss'

export const NotFoundPage: React.FC = () => (
  <Content
    className={classes.container}
  >
    <h1>Page not found</h1>
  </Content>
)
