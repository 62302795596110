import * as React from 'react'

import { Content } from 'sdk/components'

import { Text, TextName } from '../../text/Text'

import classes from './style.module.scss'

interface ComponentProps {
  title: string
  description: string
  actionButton: React.ReactNode
  imageLink: string
  videoLink?: string
}

export const SimpleTitleButtonHero: React.FC<ComponentProps> = (props) => {
  const {
    title,
    description,
    actionButton,
    imageLink,
    videoLink,
  } = props

  return (
    <Content
      image={imageLink}
      videoLink={videoLink}
      mainWrapperClassName={classes.wrapper}
      className={classes.container}
    >
      <Text
        type={TextName.H1}
        className={classes.heading}
      >
        { title }
      </Text>

      <Text
        type={TextName.Text}
        className={classes.description}
      >
        { description }
      </Text>

      { actionButton }
    </Content>
  )
}
