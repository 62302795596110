import * as React from 'react'
import { Provider } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'

import store, { initializeStore } from 'redux/store'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination'
import 'shared/styles/swiper.style.scss'
import { AppRouter } from 'apps/main/app-component/AppRouter'
import { savePreloadedData } from 'apps/main/app-component/utils'

import { EntryPointProps } from 'core/interfaces'
import { initSentry } from 'core/utils/init-sentry'

smoothscroll.polyfill()

initSentry('https://bf94b0f80dc74175bb23f90ccbd06d12@o1166364.ingest.sentry.io/6256802')

export const AppEntryPoint: React.FC<EntryPointProps> = (props) => {
  React.useEffect(() => {
    initializeStore(store, props)
    savePreloadedData(props.preloaded_data)
  }, [])

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
}
