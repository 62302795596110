import { createSlice } from '@reduxjs/toolkit'

import { FlashProps, LinksRecord } from 'core/interfaces'

import type { PayloadAction } from '@reduxjs/toolkit'

interface SettingsState {
  links: LinksRecord | undefined
  mobile: boolean | undefined
  initialized: boolean
  debug: boolean | undefined
  hasJobs: boolean | undefined
  flash: FlashProps
}

const DefaultFlashProps: FlashProps = {
  notice: undefined,
}

const initialState: SettingsState = {
  links: undefined,
  mobile: undefined,
  initialized: false,
  debug: undefined,
  hasJobs: undefined,
  flash: DefaultFlashProps,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initApp: (state, {
      payload: { links, mobile, debug, hasJobs, flash },
    }: PayloadAction<Omit<SettingsState, 'initialized'>>) => {
      state.initialized = true
      state.links = links
      state.mobile = mobile
      state.debug = debug
      state.hasJobs = hasJobs
      state.flash = flash
    },
    resetFlash: (state) => {
      state.flash = DefaultFlashProps
    },
  },
})

// Action creators are generated for each case reducer function
export const { initApp, resetFlash } = settingsSlice.actions

export default settingsSlice.reducer
