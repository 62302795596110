import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import {
  HomePage,
  JobItemPage,
  JobsPage,
  Layout,
  NewsfeedIndexPage,
  NewsfeedItemPage,
  NotFoundPage,
  ProductsPage,
  StaticPage,
} from 'pages'
// import { ChangePassword, SignIn, SignUp, SubmitEmail } from 'popups'
import { useAppSelector } from 'redux/store'

import { PreloadedDataType, PreloadedRecordType } from 'core/interfaces'

import { App } from './App'
import { fetchPreloadedDataById } from './utils'

// const unsignedUserOnlyLoader = (loggedIn: boolean): () => Response | null => {
//   return () => {
//     if (loggedIn) {
//       return redirect('/user-profile')
//     }
//
//     return null
//   }
// }

const buildRouter = (
  loggedIn: boolean,
): ReturnType<typeof createBrowserRouter> => createBrowserRouter([
  {
    path: '/',
    element: <App><Layout><Outlet /></Layout></App>,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'products',
        element: <ProductsPage />,
      },
      // {
      //   path: 'use-our-tech',
      //   element: <UseOurTechPage />,
      // },
      // {
      //   path: 'support',
      //   element: <SupportPage />,
      // },
      {
        path: 'cookie-policy',
        element: <StaticPage />,
        loader: async ({ request }) => {
          return fetchPreloadedDataById({
            record: PreloadedRecordType.StaticPage,
            type: PreloadedDataType.Item,
          }) || fetch(
            '/cookie-policy.json',
            { signal: request.signal },
          )
        },
      },
      {
        path: 'privacy-policy',
        element: <StaticPage />,
        loader: async ({ request }) => {
          return fetchPreloadedDataById({
            record: PreloadedRecordType.StaticPage,
            type: PreloadedDataType.Item,
          }) || fetch(
            '/privacy-policy.json',
            { signal: request.signal },
          )
        },
      },
      {
        path: 'terms-of-use',
        element: <StaticPage />,
        loader: async ({ request }) => {
          return fetchPreloadedDataById({
            record: PreloadedRecordType.StaticPage,
            type: PreloadedDataType.Item,
          }) || fetch(
            '/terms-of-use.json',
            { signal: request.signal },
          )
        },
      },
      {
        path: 'newsfeed',
        children: [
          {
            index: true,
            element: <NewsfeedIndexPage />,
            loader: async ({ request }) => {
              return fetchPreloadedDataById({
                record: PreloadedRecordType.Newsfeed,
                type: PreloadedDataType.Index,
              }) || fetch(
                '/newsfeed.json',
                { signal: request.signal },
              )
            },
          },
          {
            path: ':uriAlias',
            element: <NewsfeedItemPage />,
            loader: async ({ request, params }) => {
              return fetchPreloadedDataById({
                record: PreloadedRecordType.Newsfeed,
                type: PreloadedDataType.Item,
              }) || fetch(
                `/newsfeed/${params.uriAlias}.json`,
                { signal: request.signal },
              )
            },
            errorElement: <NotFoundPage />,
          },
        ],
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <JobsPage />,
            loader: async ({ request }) => {
              return fetchPreloadedDataById({
                record: PreloadedRecordType.Jobs,
                type: PreloadedDataType.Index,
              }) || fetch(
                '/jobs.json',
                { signal: request.signal },
              )
            },
          },
          {
            path: ':uriAlias',
            element: <JobItemPage />,
            loader: async ({ request, params }) => {
              return fetchPreloadedDataById({
                record: PreloadedRecordType.Jobs,
                type: PreloadedDataType.Item,
              }) || fetch(
                `/jobs/${params.uriAlias}.json`,
                { signal: request.signal },
              )
            },
            errorElement: <NotFoundPage />,
          },
        ],
      },
      // {
      //   path: 'user-profile',
      //   element: <UserProfilePage />,
      //   loader: async ({ request }) => {
      //     if (loggedIn) {
      //       return fetch(
      //         '/api/products.json',
      //         { signal: request.signal },
      //       )
      //     } else {
      //       return redirect('/sign-in')
      //     }
      //   },
      // },
      // {
      //   path: 'sign-in',
      //   element: <PopupContainer inRouter><SignIn /></PopupContainer>,
      //   loader: unsignedUserOnlyLoader(loggedIn),
      // },
      // {
      //   path: 'sign-up',
      //   element: <PopupContainer inRouter><SignUp /></PopupContainer>,
      //   loader: unsignedUserOnlyLoader(loggedIn),
      // },
      // {
      //   path: 'recovery',
      //   element: <PopupContainer inRouter><SubmitEmail /></PopupContainer>,
      //   loader: unsignedUserOnlyLoader(loggedIn),
      // },
      // {
      //   path: 'change-password',
      //   element: <PopupContainer inRouter><ChangePassword /></PopupContainer>,
      //   loader: unsignedUserOnlyLoader(loggedIn),
      // },
      // {
      //   path: 'resend-confirmation',
      //   element: <PopupContainer inRouter><SubmitEmail passwordRecovery={false} /></PopupContainer>,
      // },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
])

export const AppRouter: React.FC = () => {
  const { loggedIn } = useAppSelector((state) => state.user)
  const initialized = useAppSelector((state) => state.settings.initialized)

  const router = React.useMemo(() => {
    if (!initialized) {
      return null
    }

    return buildRouter(loggedIn)
  }, [initialized, loggedIn])

  if (!router) {
    return null
  }

  return (
    <RouterProvider router={router} fallbackElement={<App><Layout><br /></Layout></App>} />
  )
}
