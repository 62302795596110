import * as React from 'react'

import { Text, TextName } from 'sdk'
import { NewsfeedMediumCard } from 'pages/newsfeed/components/newsfeed-medium-card/NewsfeedMediumCard'

import { NewsfeedEntry } from 'core/interfaces'

import classes from './style.module.scss'

interface ComponentProps {
  relatedItems: NewsfeedEntry[];
}

export const MoreItems: React.FC<ComponentProps> = ({
  relatedItems,
}) => {
  return (
    <div className={classes.container}>
      <Text type={TextName.H2} className={classes.header}>
        What Else To Check out?
      </Text>
      <div className={classes.blocks}>
        { relatedItems.map((entry, index) => (
          <NewsfeedMediumCard key={index} entry={entry} />
        )) }
      </div>
    </div>
  )
}
