import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper'

import { ProductItemData } from './interfaces'
import { ProductItem } from './product-item/ProductItem'

import classes from './style.module.scss'

interface ComponentProps {
  products: ProductItemData[]
}

export const ProductsCarousel: React.FC<ComponentProps> = ({ products }) => {
  return (
    <Swiper
      autoplay={{
        delay: 5_000,
        disableOnInteraction: true,
      }}
      centeredSlides
      spaceBetween={50}
      slidesPerView={1}
      modules={[Autoplay, Pagination, Keyboard, Navigation]}
      navigation={{
        enabled: false,
      }}
      keyboard
      pagination={{
        clickable: true,
        type: 'bullets',
        horizontalClass: classes.bulletContainer,
        bulletClass: classes.bullet,
        bulletActiveClass: classes.active,
      }}
      breakpoints={{
        769: {
          navigation: {
            enabled: true,
          },
        },
      }}
      className={classes.carousel}
    >
      { products.map((product, index) => (
        <SwiperSlide key={index}>
          { ({ isActive }) => (
            <ProductItem
              title={product.title}
              description={product.description}
              image={product.image}
              isActive={isActive}
              link={product.link}
              backgroundAlign={product.backgroundAlign}
            />
          ) }
        </SwiperSlide>
      )) }
    </Swiper>
  )
}
