import React, { FC } from 'react'
import classNames from 'classnames'

import { Button } from 'sdk/button/Button'

import bell from './assets/bell.svg'
import checkMark from './assets/check-mark.svg'

import classes from './style.module.scss'

interface ComponentProps {
  notified: boolean
  onClick: () => void
  disabled?: boolean
}

export const NotifyButton: FC<ComponentProps> = (props) => {
  const {
    onClick,
    notified,
    disabled,
  } = props

  return (
    <Button
      onClick={onClick}
      iconUnhovered={notified ? checkMark : undefined}
      iconHovered={notified ? checkMark : bell}
      disabled={disabled}
      className={classNames(
        classes.button,
        { [classes.notified]: notified },
      )}
    >
      { notified ? 'Subscribed' : 'Subscribe' }
    </Button>
  )
}
