import * as React from 'react'
import classNames from 'classnames'

import arrowUp from './assets/arrow-up.svg'

import classes from './style.module.scss'

interface ComponentProps {
  className?: string;
}

export const BackToTopButton: React.FC<ComponentProps> = ({ className }) => {
  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <button
      className={classNames(classes.backToTopButton, className)}
      onClick={scrollToTop}
    >
      <img src={arrowUp} alt="Back to Top" />
    </button>
  )
}
