import { useAppDispatch } from 'redux/store'
import { showAlertWithDelay } from 'redux/alertReducer'

import { apiRequest, HttpMethod } from 'core/utils/request'

export enum BeNotificationEventName {
  Plugins = 'plugins',
  Mach1 = 'mach1',
  Mntra = 'mntra',
  VSL = 'vsl',
}

enum BeNotificationEventState {
  Requested = 'requested',
  Sent = 'sent',
}

interface NotificationState {
  [BeNotificationEventName.Plugins]?: BeNotificationEventState
  [BeNotificationEventName.Mach1]?: BeNotificationEventState
  [BeNotificationEventName.Mntra]?: BeNotificationEventState
  [BeNotificationEventName.VSL]?: BeNotificationEventState
}

const STORAGE_KEY = 'gpua-notifications-st'

export const setPendingBENotification = (eventName: BeNotificationEventName): void => {
  updateBeState(eventName, BeNotificationEventState.Requested)
}

export const processBENotification = (dispatch: ReturnType<typeof useAppDispatch>): void => {
  Object.entries(getBeState())
    .filter(([, state]) => state === BeNotificationEventState.Requested)
    .map(([name]) => {
      apiRequest('/api/notifications', {
        notification: { event_name: name },
      }, HttpMethod.Post).then(() => {
        updateBeState(name as BeNotificationEventName, BeNotificationEventState.Sent)
        showAlertWithDelay('You\'ll get notified', dispatch)
      })
    })
}

export const isNotificationSent = (name: BeNotificationEventName): boolean => {
  return !!getBeState()[name]
}

const updateBeState = (name: BeNotificationEventName, state: BeNotificationEventState): void => {
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...getBeState(),
      [name]: state,
    }),
  )
}

const getBeState = (): NotificationState => {
  const stateString = localStorage.getItem(STORAGE_KEY) || '{}'

  let result: NotificationState

  try {
    result = JSON.parse(stateString)
  } catch (error) {
    localStorage.setItem(STORAGE_KEY, '{}')
    result = {}
  }

  return result || {}
}
