import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

import classes from './style.module.scss'

interface ComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: ReactNode
}

export const Checkbox = forwardRef<
  HTMLInputElement,
  ComponentProps
>((props: ComponentProps, ref) => {
  const {
    className,
    label,
    ...rest
  } = props

  return (
    <div
      className={classNames(
        classes.wrapper,
        className,
      )}
      style={rest.style}
    >
      <input
        style={undefined}
        ref={ref}
        id={rest.name}
        className={classes.input}
        type="checkbox"
        {...rest}
      />
      <label
        htmlFor={rest.name}
        className={classes.label}
      >
        { label }
      </label>
    </div>
  )
})

Checkbox.displayName = 'FormControl'

Checkbox.defaultProps = {
  className: undefined,
}
